.videoControlButton {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}
.videoControlButton:hover{
    cursor: pointer;
}
.userPointsHolder{
    width: 100%;
    position: relative;
    top: 1rem;
    height: 8px;
    background-color: transparent;
    z-index: 2;
}
.diamond {
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-bottom: 10px solid #949494!important;
    position: relative;
    top: -23px;
    cursor:  pointer;
}
.diamond:after {
    content: '';
    position: absolute;
    left: -7px;
    top: 10px;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-top: 10px solid #949494;
}
input[type="range"].zoomerBar {
    -webkit-appearance: none;
}
input[type=range].styled-slider:focus {
    outline: none;
}
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #307FF4;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    margin-top: -4px;
    appearance: none;
    cursor: pointer;
}
input[type="range"]::-webkit-slider-runnable-track {
    -moz-appearance: none;
    height: 5px;
    background: #F0F3F7;
    color: #307FF4;
    appearance: none;
    width: 100%;
}
input[type="range"], input[type="range"].zoomerBar::-webkit-slider-runnable-track {
   -moz-appearance: none;
    height: 5px;
    background: #F0F3F7;
    color: #307FF4;
    appearance: none;
    width: 100%;
    cursor: pointer;
}
.zoomerBar::-webkit-slider-runnable-track, input[type="range"].zoomerBar::-moz-range-track {
    height: 5px;
    -webkit-appearance: none;
    background-color: #d9d9d9;
}
.zoomerBar::-webkit-slider-runnable-track, input[type="range"].zoomerBar::-moz-range-progress {
    height: 5px;
    background-color: #F0F3F7;
}
.zoomerBar::-webkit-slider-runnable-track:focus, input[type="range"].zoomerBar::-moz-range-track:focus {
    border: none;
}
.zoomerBar::-webkit-slider-thumb, input[type="range"].zoomerBar::-moz-range-thumb {
    -webkit-appearance: none;
    background-color: #F0F3F7;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin-top: -4px;
}
.zoomerBar::-webkit-slider-thumb:focus, input[type="range"].zoomerBar::-moz-range-thumb:focus {
    border: none;
}
input[type="range"].progressBar {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
input[type="range"].progressBar::-webkit-slider-runnable-track, input[type="range"].progressBar::-moz-range-track {
    height: 8px;
    background-color: #d9d9d9;
}
input[type="range"].progressBar::-webkit-slider-runnable-track, input[type="range"].progressBar::-moz-range-progress {
    height: 8px;
    background-color: #3788f3;
}
input[type="range"].progressBar::-webkit-slider-runnable-track:focus, input[type="range"].progressBar::-moz-range-track:focus {
    border: none;
}
input[type="range"].progressBar::-webkit-slider-thumb, input[type="range"].progressBar::-moz-range-thumb {
    background-color: #F0F3F7;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    -webkit-appearance: none;
    margin-top: -8px;
}
input[type="range"].progressBar::-webkit-slider-thumb:focus, input[type="range"].progressBar::-moz-range-thumb:focus {
    border: none;
}
.newProgressBar {
    margin: 1rem 0 1rem 0;
    width: 100%;
    height: 5px;
    background-color: #CDE1FF;
    position: relative;
    cursor: pointer;
    z-index: 10;
}
.viewedProgressBar{
    background-color: #307FF4;
    position: absolute;
    height: 100%;
    transform-origin: 0;
    z-index: 55;
    width: 100%;
}
.scroller {
    width: 14px;
    border-radius: 10px;
    opacity: 1;
    background-color: #307FF4;
    position: absolute;
    height: 14px;
    top: -4px;
    transform-origin: 0;
    left: -7px;
}
.scroller:hover{
    cursor: pointer;
}
.userPointAdder {
    position: absolute;
    width: 30px;
    top: -50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    border-color: #C1CDDE;
    background-color:  #F0F3F7;
}
.userPointAdder > img {
    cursor: pointer;
    margin: 3px;
}
.positionLine{
    z-index: 1000   ;
    opacity: 0.5;
    border-left: solid #212121 2px;
    height: calc(100vh - 60vh);
    position: absolute;
    left: 6px;
    top: 14px;
}
.positionLine.orange{
    border-left: solid #eaa745 2px;
    top: 9px;
}
.video-player-controls-wrapper{
    flex: 0;
}

.video-player-controls-wrapper .ant-slider-track::after {
    content: '';
    display: block;
    height: 100vh;
    /* background: #C1CDDE; */
    width: 1px;
    position: absolute;
    right: -1px;
    cursor: col-resize;
    padding-right: 3px;
    border-right: 1px solid #C1CDDE;
}