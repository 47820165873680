.playerSkipButton {
  position: absolute;
  z-index: 100;
  top: -36px;
  right: 15px;
  background-color: #d9d9d9;
}

.playerOtherSelectButton {
  position: absolute;
  z-index: 100;
  top: -36px;
  right: 100px;
  background-color: #d9d9d9;
}

.playerSkipButton.skipped {
  background-color: #999;
  color: #fff;
}
