.eventsViewSwitchContainer {
  display: flex;
  vertical-align: middle;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 34px;
  padding: 2px;
  margin-left: 12px;
}

.eventsViewSwitchContainer .ant-radio-button-wrapper {
  background-color: #F5F5F5;
  border-radius: 40px;
  /* padding: 12px; */
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  border: none;
}

.eventsViewSwitchContainer .ant-radio-button-wrapper::before {
  display: none;
}

.eventsViewSwitchContainer .ant-radio-button-wrapper-checked {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  font-weight: 600;
  color: #000;
}