.hotkey {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  height: 20px;
  width: 20px;
  padding: 0px 4px 0px 4px;
  border-radius: 4px;
  background: #e6f4ff;
  box-shadow: 0px 4px 8px 0px #0000001f;
  color: #002c8c;
}

.ant-btn:disabled .hotkey {
  background: #00000026;
  color: #fff;
}
.ant-btn.blocked {
  cursor: not-allowed;
}