@supports (-moz-appearance: none) {
  .recordedEventsContainer {
    /* width: 100vw; */
  }
}
@supports (-webkit-font-smoothing: antialiased) {
  .recordedEventsContainer {
    /* width: calc(100vw + 4px); */
  }
}

.recordedEventsContainer {
  background-color: white;
  flex-grow: 1;
  scrollbar-width: 4px;
  /*overflow-x: scroll;*/
  /*overflow-x: hidden;*/
}
.recordedTracksContainer {
  background-color: white;
  margin-bottom: 1rem;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 8px;
  /*padding-top: 3rem;*/
}
.eventRecorderHolder {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 2px;
  /*overflow-x: hidden;*/
  -webkit-touch-callout: none;
  user-select: none;
}

.eventRecorderHolder > div {
  text-align: left;
}
.eventRecorderHolder > div > div {
  /*margin-left: 1rem;*/
  /*margin-right: 1rem;*/
}

.tooltipIconsContainer {
  position: relative;
  display: inline-block;
  width: 79px;
  bottom: 100%;
  left: 50%;
  height: 51px;
  background: #ececec;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 2;
}
.tooltipIcons {
  visibility: visible;
  width: 79px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}
.eventTrackName {
  color: #767676;
  margin-top: 13px;
  margin-left: 1rem;
}
.tooltip .top i::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #ececec;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}
.top {
  display: block;
  position: absolute;
  background-color: #ececec;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  padding: 3px;
  border-radius: 4px;
  z-index: 99999;
}
.top > div > img:hover {
  cursor: pointer;
}
.event-track-container {
  height: 16px;
  position: relative;
  margin-left: 1rem;
  margin-right: 1rem;
}

.event-track-container .react-draggable {
  cursor: ew-resize;
}

.possessionEventDeleteButton {
  position: absolute;
  left: 50%;
  margin-left: -12px;
  top: -24px;
}