:root {
  --gray-bg: rgba(43, 49, 56, 0.7);
  --black: #212121;
  --table-table-stroke: #c1cdde;
  --white: white;
  --new-main: #3a78f1;
  --colorIcon: rgba(0, 0, 0, 0.45);
  --Primary-5: #40A9FF;
  overflow: hidden;
  --colorFill: rgba(0, 0, 0, 0.15);
  --colorPrimaryHover: rgba(60, 137, 232, 0.4);

}
body {
  overflow: hidden;
  height: 100vh;
}
.App {
  height: 100vh;
}

::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
  border-radius: 2px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the track */
}
::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
}
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}
/* Buttons */
::-webkit-scrollbar-button:single-button {
  background-color: #bbbbbb;
  display: block;
  border-style: solid;
  height: 13px;
  width: 13px;
}
/* Up */
::-webkit-scrollbar-button:single-button:horizontal:decrement {
  border-width: 8px 8px 8px 0px;
  border-color: transparent #555555 transparent transparent;
}

::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  border-color: transparent #777777 transparent transparent;
}
/* Down */
::-webkit-scrollbar-button:single-button:horizontal:increment {
  border-width: 8px 0px 8px 8px;
  border-color: transparent transparent transparent #555555;
}
::-webkit-scrollbar-button:horizontal:single-button:increment:hover {
  border-color: transparent transparent transparent #777777;
}
video {
  border-radius: 12px;
}
/* iframe#webpack-dev-server-client-overlay{display:none!important} */
