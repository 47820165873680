.container {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.innerContainer{
    display: table-cell;
    vertical-align: middle;
}
.authFormHolder {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 400px;
}
.loginWindowHeader {
    color: #000000;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

}
.authForm {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    text-align: left;
}
.authForm > input, .authForm > div >input {
    height: 40px;
    padding: 11px;
    background: #F0F0F0;
    border-radius: 5px;
    font-size: 19px;
    font-weight: 400;
    border: none;
    width: 100%;

}
.authForm > div {
    display: flex;
    flex-direction: row;
}
.authForm > label {
    margin-top: 24px;
    margin-bottom: 8px;
}
.authForm > a {
    margin-top: 7px;
    font-size: 14px;
    color: #000000;
    opacity: 0.4;
}
.loginButton {
    margin-top: 3rem;
    font-size: 18px;
    font-weight: 700!important;
    background-color: #000000!important;
    color: white;
    border-radius: 32px!important;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 113px!important;
    height: 46px!important;
    cursor: pointer;
}
.passwordImage {
    /*position: absolute;*/
    margin-left: -50px;
    margin-top: 10px;
    cursor: pointer;
}
