.enabledClickable{
    cursor: pointer;
}
.sortableAttributesContainer{
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    height: 100%;
    flex-grow: 1;
}
.sortableAttribute{
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    justify-content: space-between;
}
.sortableAttribute > div {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
}
.modalTitle{
    color: var(--black, #212121);

    /* inter_20/20_sb_(24) */
    /*font-family: Inter;*/
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
}
.modalActionButtonsContainer{
    width: 100%;
    display: flex;
    gap: 8px;
}
.modalActionButtonsContainer > button {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid var(--table-table-stroke, #C1CDDE);
    background: var(--white);
    cursor: pointer;
}
.modalActionButtonsContainer > button.modalActionButton {
    border: 1px solid var(--new-main, #3A78F1);
    background: var(--new-main, #3A78F1);
    color: var(--white);
}
