.playback-selector {
    border-radius: 8px;
    background-color: white;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -330px;
    left: -0.4rem;
    z-index: 9999;
    overflow: visible;
    display: block;   
}
.playback-control,
.playback-selector{
  color: #9FAAB8;
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
  font-family: "Roboto Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  text-transform: uppercase;
  cursor: pointer;
}
.playback-container {
    display: flex;
    flex-direction: column;
}
.playback-container > div {
    margin: auto;
    padding: 10px;
    font-size: 14px;
}
.playback-container :not(:last-child) {
    border-bottom: 1px solid rgba(159, 170, 184, 0.39);
}
.playback-button {
    cursor: pointer;
}
.bold {
    color: #212121;
    font-weight: bold;
}
.scrolling-buttons-wrapper{
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center; 
    gap: 8px;
    -webkit-touch-callout: none;
    user-select: none;
}
