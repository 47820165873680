.headerMessageWrapper {
  position: absolute;
  top: 30px;
  border-radius: 10px;
  background-color: #e6effd;
  padding: 17px;
  text-align: left;
  z-index: 10;
  left: -200px;
}

.messagesIcon {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.messagesIcon > image {
  cursor: pointer;
}
