.add-periods-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6px;
}
.add-periods-control{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.teamSwitcherContainer .ant-radio-checked::after {
  border-color: #000 !important;
  border-width: 4px;
}

.teamSwitcherContainer .ant-radio-checked .ant-radio-inner {
  background-color: #fff;
}

.teamSwitcherContainer .ant-radio-checked .ant-radio-inner::after {
  background-color: #000;
}