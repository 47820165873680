.colorSelectListItem {
  margin: 0 4px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-start-start-radius: 50% !important;
  border-end-start-radius: 50% !important;
  border-start-end-radius: 50% !important;
  border-end-end-radius: 50% !important;
  border-inline-start-width: 1px !important;
  padding-inline: 0 !important;
}

.colorSelectListItem:first-child {
  margin-left: 0;
}

.colorSelectListItem:last-child {
  margin-right: 0;
}

.colorSelectListItem::before {
  display: none !important;
}

.ColorSelectListContainer {
  margin: 8px 0;
}
/* 
.bgCircle {
  width: 95%;
  height: 95%;
  border-radius: 50%;
} */
