.ant-modal-mask {
  background: transparent;
  pointer-events: none;
}
.ant-modal-wrap {
  pointer-events: none;
}
.ant-modal {
  pointer-events: all;
}
.field-modal button.ant-modal-close,
.field-modal button.ant-modal-close:hover {
  position: absolute;
  right: -10px;
  top: -10px;
  height: 25px;
  width: 25px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 20px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  user-select: none;
}
.fieldOpacitySliderContainer, .fieldOpacityPlayerSliderContainer, .fieldSizePlayerSliderContainer {
  position: absolute;
  right: 0;
  height: 150px;
}

.fieldOpacityPlayerSliderContainer {
  top:200px;
}

.fieldSizePlayerSliderContainer {
  top:400px;
}

.coordinatesModalContainer.football,
.coordinatesModalContainer.football_30 {
  height: 752px;
  box-sizing: border-box;
  aspect-ratio: 252/163;
  position: relative;
}
.coordinatesModalContainer.futsal {
  height: 652px;
  box-sizing: border-box;
  aspect-ratio: 456/227;
  position: relative;
}
.coordinatesModalContainer img {
  user-select: none;
}

.playerOnMapBlob {
  /* height: 2.2em;: 32px;
  height: 32px; */
  height: 2.2em;
  width: 2.2em;
  font-size: 1em;
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  vertical-align: middle;
  box-sizing: border-box;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -1.1em;
  margin-left: -1.1em;
  z-index: 11;
  cursor: none;
  pointer-events: none;
  user-select: none;
}

.playerOnMapBlob.awayTeam {
  margin-top: -1.1em;
  margin-left: 0;
  margin-bottom: 0;
  margin-right: -1.1em;
}

.invertedAwayDisplay {
  margin: 0 !important;
  margin-left: -1.1em !important;
  margin-bottom: -1.1em !important;
  margin-right: -1.1em !important;
}

.invertedHomeDisplay {
  margin: 0 !important;
  margin-left: -1.1em !important;
  margin-bottom: -1.1em !important;
  margin-right: -1.1em !important;
}

.aimHorisontal, .aimVertical {
  position: absolute;
  width: 2px;
  height: 2px;
  background-color: #fff;
  opacity: 0.8;
  pointer-events: none;
  user-select: none;
}

.aimHorisontal {
  width: 100%;
}

.aimVertical {
  height: 100%;
}

.prevPoint {
  width: 10px;
  height: 10px;
  margin-left: -5px;
  margin-top: -5px;
  position: absolute;
  background-color: rgb(255, 162, 0);
  border-radius: 50%;
  pointer-events: none;
}