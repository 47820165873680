.game-protocol-title{
    display: flex;
    flex-direction: row;
    padding: 24px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    background: var(--colorBgElevated, #FFF);

    /* boxShadow */
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
    justify-content: space-between;
    justify-items: center;
}
.game-protocol-details{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.game-protocol-tournament{
    text-wrap: nowrap;
    display: flex;
    align-items: center;

    overflow: hidden;
    color: var(--colorText, rgba(0, 0, 0, 0.88));
    text-align: center;
    text-overflow: ellipsis;

    /* SM/SM Normal */
    font-family: "SFProTextRegular";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
}
.game-protocol-details .date{
    overflow: hidden;
    color: var(--colorTextTertiary, rgba(0, 0, 0, 0.45));
    text-align: center;
    text-overflow: ellipsis;

    /* SM/SM Normal */
    font-family: "SFProTextRegular";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
}
.game-protocol-details .protocol-score{
    display: flex;
    gap: 8px;
    color: var(--colorInfoText, #002C8C);

    /* Heading/Heading 3 */
    font-family: "Roboto Condensed";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.96px;
    text-transform: uppercase;
}
.protocol-score{
    flex: 1;
    display: flex;
    justify-content: center;
}
.game-protocol-team-name{
    overflow: hidden;
    color: var(--colorTextHeading, rgba(0, 0, 0, 0.88));
    text-overflow: ellipsis;
    font-family: "Roboto Condensed";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 120% */
    letter-spacing: 0.6px;
    text-transform: uppercase;
}
.game-protocol-logo{
  height: 56px;
}
.game-protocol-team{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
}
.game-protocol-lineup-player-wrapper{
    padding: 12px 8px;
    border-bottom: 1px solid var(--colorBorderSecondary, rgba(0, 0, 0, 0.06));
}
.player-number{
    border-radius: 6px;
    background: var(--colorBorderSecondary, rgba(0, 0, 0, 0.06));
    display: flex;
    width: 24px;
    height: 22px;
    justify-content: center;
    align-items: center;

    width: 24px;
    flex-shrink: 0;

    color: var(--colorInfoText, #002C8C);
    text-align: center;
    font-family: "Roboto Condensed";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */   
}
.game-protocol-lineup-title{
    display: flex;
    padding: 24px 24px 8px 24px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    background: var(--colorBgElevated, #FFF);
    color: var(--colorPrimaryText, #002C8C);
    text-align: center;
    flex: 1;

    /* Heading/Heading 5 */
    font-family: "Roboto Condensed";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.96px;
    text-transform: uppercase;
    justify-content: center;
}

.game-protocol-lineup-player-wrapper .timing{
    /* width: 24px; */
    color: var(--colorTextTertiary, rgba(0, 0, 0, 0.45));
    text-align: center;

    /* SM/SM Normal */
    font-family: "SFProTextRegular";
    font-size: 14px;
    font-style: normal;
    line-height: 20px; /* 166.667% */
}
.substitution-name{
    color: var(--colorTextTertiary, rgba(0, 0, 0, 0.45));

    /* SM/SM Strong */
    font-family: "SF Pro Text Bold";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 166.667% */
}

.game-protocol-header {
    margin: 0;
    padding-left: 8px;
    padding-right: 8px;
    font-weight: 700;
}

.game-protocol-header .ant-divider {
    margin-top: 12px;
    margin-bottom: 12px;
}

.triangle-down {
   width: 0px;
   height: 0px;
   border-style: solid;
   border-width: 10.4px 6px 0 6px;
   border-color: #A8071A transparent transparent transparent;
   transform: rotate(0deg);
}

.triangle-up {
   width: 0px;
   height: 0px;
   border-style: solid;
   border-width: 0 6px 10.4px 6px;
   border-color: transparent transparent #237804 transparent;
   transform: rotate(0deg);
}