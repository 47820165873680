.teamSwitcherContainer .ant-radio-checked::after {
  border-color: #000 !important;
}

.teamSwitcherContainer .ant-radio-checked .ant-radio-inner {
  background-color: #fff;
}

.teamSwitcherContainer .ant-radio-checked .ant-radio-inner::after {
  background-color: #000;
}
.hotkey-in-radio {
  position: absolute;
  right: 0.5rem;
  top: 0.2rem;
  height: 20px;
  width: 20px;
  padding: 0px 4px 0px 4px;
  border-radius: 4px;
  background: #e6f4ff;
  box-shadow: 0px 4px 8px 0px #0000001f;
  color: #002c8c;
}
.ellipsis{
  white-space: 'nowrap';
  overflow: 'hidden';
  text-overflow: 'ellipsis';
}