.modalContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
}
.modal {
  margin: auto;
  width: 90%;
  max-width: 40rem;
  background-color: white;
  border-radius: 4px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.modalContent {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  padding: 26px;
}
.modalHeader {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modalContainer label {
  color: #000000;
  opacity: 0.4;
  margin-bottom: 10px;
}
.actionButton {
  margin-top: 59px;
  color: white;
  background-color: #307ff4;
  border-radius: 4px;
  padding: 9px;
  border: 0;
  font-size: 14px;
  cursor: pointer;
}
.actionButton:active {
  outline: 1px solid white;
  outline-offset: -1px;
}
.taskComment {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  text-align: left;
  margin-top: 26px;
  white-space: pre-wrap;
}
.taskEvents {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  text-align: left;
  margin-top: 26px;
}
.eventsHolder {
  display: flex;
  flex-wrap: wrap;
}
.attributeValueModalClass {
  background-color: transparent;
  border: solid 1px #9faab8;
  padding: 5px;
  font-size: 12px;
  border-radius: 20px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
