.playersFieldContainer {
  aspect-ratio: 227/147;
  position: relative;
}

.bgImage {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.playersField {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.089);
  z-index: 100;
}

.playersFieldItem {
  height: 2.2em;
  width: 2.2em;
  font-size: 1em;
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  vertical-align: middle;
  box-sizing: border-box;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -1.1em;
  margin-left: -1.1em;
}

.playersFieldSelect {
  min-width: 20vw;
}
.playersFieldSelect .disabled {
  cursor: not-allowed;
  color: #d9d9d9 !important;
}
.playersFieldSelect .disabled .playerNumber{
  color: #d9d9d9 !important;
  background-color: #F5F5F5 !important;
}

.playerData {
  width: 100%;
  flex-wrap: nowrap;
  white-space: nowrap;
  display: inline-flex;
  /* width: min-content; */
  cursor: pointer;
  position: relative;
  align-items: center;
  vertical-align: middle;
  border-radius: 8px;
  padding: 4px;
  background-color: rgba(0, 0, 0, 0);
  transition: 0.3s;
  overflow: hidden;
  text-overflow: ellipsis;
}

.playerData .hoveredPlus {
  display: none;
  position: absolute;
  right: 10px;
}

.playerData:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

.playerData:hover .hoveredPlus {
  display: block;
}

.playerNumber {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  margin-right: 6px;
  line-height: 24px;
}

.managePlayers .playerContainerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.managePlayers h3 {
  margin-top: 0;
}

.managePlayers .ant-card-body {
  padding: 12px;
  background-color: #E6F4FF;
}

.managePlayers .playerContainer {
  width: 100%;
}

.managePlayers .ant-spin-container, .managePlayers .ant-spin-nested-loading {
  height: 100%;
}

.playersFieldContainer .teamContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.playersFieldItem.isActive {
  border: 2px #fff solid;
  opacity: 1 !important;
}

.playersFieldItem.deactivated {
  opacity: 0.5;
}

.playersFieldItem.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.overflowPlayersContainer {
  max-height: 40vh;
  overflow-y: auto;
}

.swapBackdrop {
  opacity: 0.4;
  background-color: #000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  top: 0;
  left: 0;
}