.segment{
    height: 16px;
}
.recordedNewSegment {
    background-color: #F86449 !important;
}

.placementSegment {
    box-sizing: border-box;
    border-left: solid 1px #9FAAB8;
    border-right: solid 1px #9FAAB8;
}

.editedSegment {
    background-color: #307FF4;
    outline: solid 3px  #9FAAB8;
}
.newSegmentTrack {
    background-color: #307FF4;
}
.currentlyViewedSegmentTrack {
    background-color: #EF9F28;
}
.leftDraggableHandle > img, .rightDraggableHandle > img{
    top: -12px;
}

.leftDraggableHandle{
    color: white;
    border-radius:  5px 0 0 5px!important;
    transform: scale(0.5);
    z-index: 10;
    background: #9FAAB8!important;
    position: absolute;
    height: 22px;
    padding:0px 3px 0px 3px;
    font-weight: bold;

}
.rightDraggableHandle {
    color: white;
    font-weight: bold;
    border-radius:  0 5px 5px 0!important;
    transform: scale(0.5);
    z-index: 10;
    background: #9FAAB8!important;
    position: absolute;
    height: 22px;
    padding:0px 3px 0px 3px;
    font-weight: bold;
}
.segments-container{
  display: flex;
  align-items: center;
  background-color: '#F0F3F7';
  height: 100%;
  position: relative;
  border-radius: 12px;
  border: 1px solid var(--colorSplit, rgba(0, 0, 0, 0.06));
  background: var(--colorBgLayout, #F5F5F5);
}

.rhombus {
    position: absolute;
    width: 8px;
    height: 8px;
    transform: rotate(45deg) skew(25deg, 25deg);
    margin-top: 3px;
    background: rgb(48, 127, 244);
}

.rhombusYellow {
    background-color: #EF9F28;
}