.modalContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
}
.modalCopyModal {
  position: relative;
  margin: auto;
  width: 90%;
  max-width: 437px;
  background-color: white;
  border-radius: 12px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.modalContent {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  padding: 26px;
}
.modalHeader {
  text-align: left;
}
.modalContainer label {
  color: #000000;
  opacity: 0.4;
  margin-bottom: 10px;
}
.actionButton {
  margin-top: 59px;
  background-color: #2d06b9;
  border-radius: 4px;
  padding: 9px;
  border: 0;
  font-size: 14px;
  cursor: pointer;
}
.actionButton:active {
  outline: 1px solid #2d06b9;
  outline-offset: -1px;
}
.taskComment {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  text-align: left;
  margin-top: 26px;
}
.taskEvents {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  text-align: left;
  margin-top: 26px;
}
.eventsHolder {
  display: flex;
  flex-wrap: wrap;
}
.attributeValueClass.copy {
  background-color: rgba(246, 248, 254, 0.7);
  padding: 9px;
  font-size: 12px;
  color: #307ff4;
  border: solid 1px #307ff4;
  border-radius: 20px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.attributeValueClass.inactive {
  background-color: #e6effd;
}
.finishCopyButton {
  color: #307ff4;
  cursor: pointer;
  text-align: right;
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
}
