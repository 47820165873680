.editedEventActionsModalContainer {
  border-radius: 8px;
  border: 1px solid var(--table-table-stroke, #c1cdde) !important;
  background: #f5f5f7;

  /* shadow */
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 16px;
  align-items: center;
  gap: 24px;
}
.editedEventActionsModalContainer:focus-visible {
  outline: none;
}
svg:focus-visible,
path:focus-visible,
:focus-visible,
:focus,
::-moz-focus-outer {
  outline: none;
}
.hoverBlueIcon:hover path {
  stroke: #3a78f1 !important;
}
.hoverBlueFillIcon:hover path:nth-child(1) {
  fill: #3a78f1 !important;
}
.advancedTooltip {
  border-radius: 6px !important;
  background: #2b3138;
}
